const isProd = process.env.NODE_ENV === 'production';
const HOST = isProd ? process.env.VUE_APP_PRODUCTION_HOST : process.env.VUE_APP_DEVELOPMENT_HOST;

export const SOCIAL = {
  KAKAO: {
    CALLBACK: `${HOST}/oauth/callback/KAKAO`,
  },
  NAVER: {
    CALLBACK: `${HOST}/oauth/callback/NAVER`,
  }
}