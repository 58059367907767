
import { Component, Vue } from "vue-property-decorator";
import TextBoxModal from "@/components/modal/make/option/TextBoxModal.vue";

interface RequestType {
  email: string;
  mobile: string;
  name: string;
  nickname: string;
  type: string;
}

@Component({
  components: {TextBoxModal}
})
export default class NaverCallback extends Vue {
  socialType = '';
  boxModalId = 'box-modal-id';
  loading = true;

  mounted() {
    const { type } = this.$route.params as {type: string};
    this.socialType = type;
    this.login();
  }

  login() {
    if (this.socialType === 'KAKAO') {
      this.kakaoLoginSuccess();
    } else if (this.socialType === 'NAVER') {
      this.naverLoginSuccess();
    }
  }

  /**
   * @description 로그인한 이메일로 가입된 계정이 있는지 확인한다.
   가입 계정 true, 신규 유입 false
   * @param {RequestType} body
   */
  async findUserInfo(body: RequestType): Promise<void> {
    try {
      const { email } = body;
      const { data } = await this.axios.get(`/user/information/${email}`);
      // 데이터가 존재한다면 LOGIN_TYPE 만 추가하여 통합 계정으로서 사용하고
      const { result } = data;
      if (result) {
        // 확인했을 때만 아래 로직 실행되도록
        // 취소 눌렀을 경우 다시 로그인 화면으로 replace
        const { LOGIN_TYPE } = data.user as { LOGIN_TYPE: string[] };
        const typeCheck = LOGIN_TYPE.includes(this.socialType)
        LOGIN_TYPE.push(this.socialType)
        if (!typeCheck) {
          const sendData = {
            email,
            LOGIN_TYPE,
          }
          await this.axios.patch(`/user/login-type`, sendData);
        }
      } else {
        // 여기는 데이터가 완전 없는 것이므로 회원가입을 해야한다.
        await this.createNewUserData(body);
      }
    } catch (e) {
      await this.$router.replace('/login');
    }
  }

  /**
   * @description 회원 가입 이력이 없을 경우 USER Collection 에 DATA INSERT
   */
  async createNewUserData(body: RequestType) {
    try {
      const { type, name, nickname, mobile, email } = body;
      const sendBody = {
        type, name, nickname, mobile, email
      }
      await this.axios.post('/user/social/join', sendBody);
    } catch (e) {
      await this.$router.replace('/login');
    }
  }

  async kakaoLoginSuccess() {
    try {
      const { code } = this.$route.query as { code: string };
      const { data } = await this.axios.get(`/oauth/callback/kakao?code=${code}`)
      if (data) {
        const { result } = data;
        if (result) {
          const { email, phone_number, profile } = data.user;
          const name = data.user?.name ? data.user.name : profile.nickname;
          const phone = phone_number.split(' ')[1];
          // 받은 정보를 통하여 가입 이력 확인
          const body: RequestType = {
            email,
            mobile: phone,
            name,
            nickname: profile.nickname,
            type: this.socialType
          };
          await this.findUserInfo(body);
          await this.historyCheck(email);
          const sendData = {
            email, type: this.socialType
          };
          const { data: login } = await this.axios.post('/user/login/oauth', sendData);
          if (login) {
            const { ACCOUNT_LOCK, UNLOCK_DATE } = login
            const lockCheck = await this.accountLockCheck({ACCOUNT_LOCK, UNLOCK_DATE, USER: email});
            if (lockCheck) {
              await this.$store.dispatch('login', { data: login });
              await this.$router.replace('/project/list');
            }
          }
        }
      }
    } catch (e) {
      await this.$router.replace('/login');
    }
  }

  async naverLoginSuccess() {
    try {
      const { code, state } = this.$route.query as { code: string, state: string };
      const { data } = await this.axios.get(`/oauth/callback/naver?code=${code}&state=${state}`);
      const { result } = data;
      if (data) {
        // 회원 정보 얻기 성공
        if (result) {
          const { user } = data;
          const { email } = user;
          // 받은 정보를 통하여 가입 이력 확인
          const body: RequestType = {
            ...user,
            type: this.socialType
          };
          await this.findUserInfo(body);
          await this.historyCheck(email);
          const sendData = {
            email,
            type: this.socialType
          };
          const { data: login } = await this.axios.post('/user/login/oauth', sendData);
          if (login) {
            const { ACCOUNT_LOCK, UNLOCK_DATE } = login
            const lockCheck = await this.accountLockCheck({ACCOUNT_LOCK, UNLOCK_DATE, USER: email});
            if (lockCheck) {
              await this.$store.dispatch('login', { data: login });
              await this.$router.replace('/project/list');
            }
          }
        }
      }
    } catch (e) {
      await this.$router.replace('/login');
    }
  }

  async accountLockCheck({ACCOUNT_LOCK, UNLOCK_DATE, USER}: {ACCOUNT_LOCK: boolean, UNLOCK_DATE: Date, USER: string}): Promise<boolean | void> {
    try {
      if (ACCOUNT_LOCK) {
        const currentDate = new Date().getTime();
        const unlockDate = new Date(UNLOCK_DATE).getTime();
        const unlock = currentDate - unlockDate;
        if (unlock >= 0) {
          await this.axios.patch('/user/account-unlock', {USER})
          await this.login();
        } else {
          alert('로그인 정보를 5회 이상 잘못 입력하셨습니다. 5분 후 다시 시도해 주세요.');
          await this.$router.replace('/login');
          return;
        }
      }
      return !ACCOUNT_LOCK
    } catch (e) {
      await this.$router.replace('/login');
    }
  }

  /**
   * @description: 입력한 계정 정보가 현재 로그인되어 있는 상태인지 확인,
   *                로그인 되어 있다면 로그아웃 시킴
   * @param email
   */
  async historyCheck(email: string) {
    const idEncrypted = this.$common.rsaEncrypt(email);
    const historyData = await this.loginHistoryCheck(idEncrypted);
    if (historyData) {
      const {ACCESS, HISTORY_ID, TOKEN} = historyData;
      if (ACCESS) {
        await this.$store.dispatch('socketStore/loginCheck', { userId: email, TOKEN });
        await this.logoutOfAnotherAccount(HISTORY_ID);
      }
    }
  }

  async loginHistoryCheck(userId: string) {
    const historyData = {
      ACCESS: false,
      HISTORY_ID: '',
      TOKEN: '',
    }
    const {data} = await this.axios.post('/user/login-history-check',{userId});
    if (data) {
      const {ACCESS, _id, TOKEN} = data;
      historyData.ACCESS = ACCESS;
      historyData.HISTORY_ID = _id;
      historyData.TOKEN = TOKEN;
    }
    return historyData;
  }

  async logoutOfAnotherAccount(_id: string): Promise<boolean> {
    const { data } = await this.axios.put('/user/login-history/logout',{_id});
    const { historyUpdate } = data;
    return historyUpdate;
  }
}
