
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { IProjectValue } from '@/interface/survey/question';
import { QUESTION } from '@/types/question';
import { UserIcon, ProgressBoardIcon, PaperIcon, ChartIcon, CreditCardIcon } from '@/components/icons/common';
import { ROUTE_PATH } from '@/types/constant';
import Button from '@/components/common/Button.vue';

@Component({
  components: { Button, UserIcon, ProgressBoardIcon, PaperIcon, ChartIcon, CreditCardIcon },
})
export default class MenuTabs extends Vue {
  @Prop({ default: QUESTION.defaultSurveyInfo }) surveyInfo: IProjectValue;
  @Prop() projectId: number;

  currentTab: number = -1;

  created(): void {
    this.routePathCheck();
  }

  @Watch('route')
  routePathCheck() {
    const { name } = this.$route;
    if (name === ROUTE_PATH.SAMPLING) this.currentTab = 0;
    if (name === ROUTE_PATH.MAKE) this.currentTab = 1;
    else if (name === ROUTE_PATH.PAYMENT) this.currentTab = 2;
    else if (name === ROUTE_PATH.QUOTA) this.currentTab = 3;
    else if (name === ROUTE_PATH.RESULT) this.currentTab = 4;
  }

  tabIndexRouter(index: number) {
    if (index === this.currentTab) return;
    if (this.surveyInfo.CONFIG.SIMPLE_SURVEY_TYPE === 1 && index === 3) return;

    if (index === 0) this.$router.push(`/project/sampling/${this.projectId}`);
    if (index === 1) this.$router.push(`/project/make/${this.projectId}`);
    if (index === 2) this.$router.push(`/project/payment/${this.projectId}`);
    if (index === 3) this.$router.push(`/project/quota/${this.projectId}`);
    if (index === 4) this.$router.push(`/project/result/${this.projectId}`);
  }

  active(index: number): boolean {
    return index === this.currentTab;
  }

  disabled(pass?: boolean): boolean {
    // 리스트 조사의 경우 링크 확정이 아니면 disabled
    const isListProject = this.surveyInfo.CONFIG.SIMPLE_SURVEY_TYPE === 2;
    if (isListProject) {
      if (this.surveyInfo.CONFIG.LINK_STATUS)
        return !(this.surveyInfo.CONFIG.LINK_STATUS >= 101 && this.surveyInfo.CONFIG.LINK_STATUS <= 105);
      return true;
    } else {
      if (pass) {
        if (this.surveyInfo.CONFIG.LINK_STATUS)
          return !(this.surveyInfo.CONFIG.LINK_STATUS >= 101 && this.surveyInfo.CONFIG.LINK_STATUS <= 105);
      }
      // 그게 아니라면 패널인데
      return true;
    }
  }
}
