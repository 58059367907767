import Vue from 'vue';
import Vuex from 'vuex';
import { ins as axios } from '@/utils/axios';
import { IUserSessionInfo } from '@/interface/user/user';
import { ADMIN_ID, ROLE } from '@/types/user';
import utilStore from '@/store/utils';
import socketStore from "@/store/socket";
import createPersistedState from "vuex-persistedstate";
import downloadEventHandler from "@/store/socket/event/DownloadEventHandler";
import makeStore from "@/store/make";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: sessionStorage.hasOwnProperty('token'),
    token: sessionStorage.token || '',
    role: sessionStorage.role || '',
    autoLogin: false,
    userId: sessionStorage.userId || '',
    listMode: 'card',
    userName: sessionStorage.userName || '',
    userNickname: sessionStorage.userNickname || '',
    historyId: sessionStorage.historyId || '',
    loginType: sessionStorage.loginType || '',
  },
  getters: {
    userNickname: (state: IUserSessionInfo) => {
      state.userNickname = sessionStorage.userNickname || '';
      return state.userNickname;
    },
    isLogin: (state: IUserSessionInfo) => {
      state.isLogin = sessionStorage.hasOwnProperty('token');
      return state.isLogin;
    },
    token: (state: IUserSessionInfo) => {
      state.token = sessionStorage.token || '';
      return state.token;
    },
    autoLogin: (state: IUserSessionInfo) => {
      return state.autoLogin;
    },
    userId: (state: IUserSessionInfo) => {
      state.userId = sessionStorage.userId || '';
      return state.userId;
    },
    listMode: (state: IUserSessionInfo) => {
      return state.listMode;
    },
    loginType: (state: IUserSessionInfo) => {
      return state.loginType;
    },
    userName: (state: IUserSessionInfo) => {
      state.userName = sessionStorage.userName || '';
      return state.userName;
    },
    userRole: (state: IUserSessionInfo) => {
      state.role = sessionStorage.role || '';
      return state.role;
    },
    isAdmin: (state: IUserSessionInfo) => {
      const { role, userId } = state;
      return role === ROLE.ADMIN || userId === ADMIN_ID;
    },
    historyId: (state: IUserSessionInfo) => {
      state.historyId = sessionStorage.historyId || '';
      return state.historyId
    }
  },
  mutations: {
    logOut(state: IUserSessionInfo) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userName');
      sessionStorage.removeItem('userNickname');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('historyId');
      sessionStorage.removeItem('loginType');
      state.token = false;
      state.userNickname = '';
      state.userName = '';
      state.userId = '';
      state.role = '';
      state.historyId = '';
      state.loginType = '';
      state.isLogin = false;
    },
    login(state: IUserSessionInfo, { data }) {
      sessionStorage.token = data.token || '';
      sessionStorage.userName = data.NAME || '';
      sessionStorage.userId = data._id || '';
      sessionStorage.userNickname = data.NICKNAME || '';
      sessionStorage.role = data.ROLE || '';
      sessionStorage.historyId = data.HISTORY_ID || '';
      sessionStorage.loginType = data.LOGIN_TYPE || '';
      state.token = sessionStorage.token;
      state.userName = sessionStorage.userName || '';
      state.userNickname = sessionStorage.userNickname || '';
      state.userId = sessionStorage.userId || '';
      state.role = sessionStorage.role || '';
      state.historyId = sessionStorage.historyId || '';
      state.loginType = sessionStorage.loginType || '';
      state.isLogin = sessionStorage.hasOwnProperty('token');
    },
    listMode(state: IUserSessionInfo, { listMode }) {
      state.listMode = listMode;
    },
    changeNickname(state: IUserSessionInfo, newNickname) {
      state.userNickname = newNickname;
    },
    setToken(state: IUserSessionInfo, token: string) {
      sessionStorage.token = token;
      state.token = sessionStorage.token;
    },
  },
  actions: {
    async login(context, { data }) {
      await context.commit('login', { data });
    },
    async logOut(context, payload) {
      await Promise.all([
        await context.dispatch('socketStore/loginHistoryUpdate', payload),
        await context.commit('logOut'),
      ]);

    },
    async verify(context) {
      try {
        const { data } = await axios.get(`/user/verify`);
        if (data) {
          const { role, token, userId, userName, userNickname, HISTORY_ID, LOGIN_TYPE } = data;
          const payload = {
            _id: userId,
            NAME: userName,
            NICKNAME: userNickname,
            token: token,
            ROLE: role,
            HISTORY_ID,
            LOGIN_TYPE
          };
          await context.commit('login', { data: payload });
        }
        return {
          result: data.hasOwnProperty('token') && data.token !== '' && data.token !== undefined && data.token !== null,
        };
      } catch (e) {
        await context.dispatch('logOut')
        return {result: false}
      }
    },
    async projectAccess(context, payload) {
      try {
        return axios.get(`/project/access/${payload}`);
      } catch (e) {
        console.log(e);
      }
    },
    listMode(context, { listMode }) {
      context.commit('listMode', { listMode });
    },
  },
  modules: {
    utilStore,
    socketStore,
    makeStore,
    downloadEventHandler,
  },
  plugins: [
    createPersistedState({
      paths: ["utilStore"],
    })],
});