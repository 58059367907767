import app from '@/main';
import * as crypto from "crypto";

class CommonClass {
  //핸드폰 정규식
  private mobileTelRegex = /^[0-9]{3}[0-9]{4}[0-9]{4}/;
  //이메일 정규식
  private emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  //숫자만
  private numberRegex = /[^0-9]/g;
  //숫자 콤마
  //private commaNumberRegex = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
  private commaNumberRegex = /\B(?=(\d{3})+(?!\d))/g;

  get documentHeight(): number {
    return document.documentElement.clientHeight - 150;
  }

  mobileTelCheck(input: string): boolean {
    return this.mobileTelRegex.test(input);
  }

  emailCheck(input: string): boolean {
    return this.emailRegex.test(input);
  }

  replaceOnlyNumber(str: string): string {
    if (str.length > 0) return str.replace(this.numberRegex, '');
    else return '';
  }

  // numberFormat(str: number | string): string {
  //   return String(str).replace(this.commaNumberRegex, ',');
  // }

  rsaEncrypt(text: string): string {
    let key = '';
    const PUBLIC_KEY = process.env.VUE_APP_RSA_PUBLIC_KEY;
    if (PUBLIC_KEY) key = PUBLIC_KEY.replace(/\\n/g, '\n');
    const convertBuffer = Buffer.from(text);
    return crypto.publicEncrypt(key, convertBuffer).toString('hex');
  }

  numberFormat(str: number | string): string {
    const number = String(str).split('.');
    number[0] = number[0].replace(this.commaNumberRegex, ',');
    return number.join('.');
  }

  trim(str: string): string {
    return str.replace(/ /gi, '');
  }

  documentTop(): void {
    window.scrollTo(0, 0);
  }

  // TITLE HTML 태그 제거
  replaceHtmlTag(text: string): string {
    text = text.replace(/<br>/gi, '\n'); // <br>을 엔터로 변경
    text = text.replace(/&nbsp;/gi, ' '); // 공백
    // HTML 태그제거
    text = text.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, '');
    text = text.replace(/<(no)?script[^>]*>.*?<\/(no)?script>/gi, '');
    text = text.replace(/<style[^>]*>.*<\/style>/gi, '');
    text = text.replace(/<(\"[^\"]*\"|\'[^\']*\'|[^\'\">])*>/gi, '');
    text = text.replace(/<\\w+\\s+[^<]*\\s*>/gi, '');
    text = text.replace(/&[^;]+;/gi, '');
    text = text.replace(/\\s\\s+/gi, '');
    return text;
  }

  onlyNumberEvent(evt): void {
    const { target } = evt;
    const { value } = target;
    target.value = String(value).replace(/[^0-9]/g, '');
    //return str.replace(this.numberRegex,'');
  }

  toNumber = (value: number | string): number => Number(value);

  sum(valueLeft: string | number, valueRight: string | number): number {
    return this.toNumber(valueLeft) + this.toNumber(valueRight);
  }

  escapeHtml(text: string): string {
    const map: {
      [index: string]: string;
      '&': string;
      '<': string;
      '>': string;
      '"': string;
      "'": string;
    } = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;',
    };
    return text.replace(/[&<>"']/g, function (m: string) {
      return map[m];
    });
  }

  checkPrecision(num: string | number, decimals: number): boolean {
    const precision = 99;
    return (
      new Number(new Number(new Number(num).toFixed(decimals)).toPrecision(precision)).toString() ===
      new Number(num).toString()
    );
  }

  limitLengthPartition(str: string) {
    return str.substring(0, 49);
  }

  getDateDay(date: string): string {
    const time: Date = new Date(date);
    let day = '';
    switch (time.getDay()) {
      case 0:
        day = '일';
        break;
      case 1:
        day = '월';
        break;
      case 2:
        day = '화';
        break;
      case 3:
        day = '수';
        break;
      case 4:
        day = '목';
        break;
      case 5:
        day = '금';
        break;
      case 6:
        day = '토';
        break;
    }
    return time.getMonth() + 1 + '월 ' + time.getDate() + '일(' + day + ')';
  }

  dateFormat(date: Date): string {
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();
    let hour: number | string = date.getHours();
    let minute: number | string = date.getMinutes();
    //let second: number| string = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    //second = second >= 10 ? second : '0' + second;

    return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  }

  numInRowToGrid(num: string): string {
    if (num == '1') return 'pure-u-1';
    if (num == '2') return 'pure-u-1 u-md-1-2';
    if (num == '3') return 'pure-u-1-2 u-md-1-3';
    if (num == '4') return 'pure-u-1-2 u-md-1-4';
    if (num == '5') return 'pure-u-1-3 u-md-1-5';
    if (num == '6') return 'pure-u-1-3 u-md-1-6';
    if (num == '7') return 'pure-u-1-4 u-md-1-8';
    if (num == '8') return 'pure-u-1-4 u-md-1-8';
    else return '';
  }

  rnd(arr, type: any, numColumn: any) {
    let srcArray: [] = arr;
    if (type == null) type = '';
    //규칙 없으면 그냥 반환
    if (type == '') return arr;
    //개수 적어도 그냥 반환
    if (arr.length < 2) return arr;

    var array = arr.map(function (a) {
      return a[numColumn];
    });

    //따옴표 제거
    type = type.replace(/"/g, '');

    //입력받은 규칙을 콤마 형태로 변환
    type = randomTypeToComma(array, type);

    var reg = /[\(\[{][a-zA-Z_0-9,]+[\)\]}]/g;
    var groups = {};
    var groupNum = 1;
    var newType;
    //작은 분류부터 그룹으로 묶음
    do {
      newType = type.replace(reg, function (match) {
        var groupName = '_GROUP_' + groupNum;
        groups[groupName] = match;
        groupNum++;
        return groupName;
      });
      if (type == newType) break;
      type = newType;
    } while (1);
    //역순으로(큰 분류부터) 랜덤 적용. _GROUP_15 등이 _GROUP_1 에 의해 치환되지 않을까 잠시 걱정했지만 큰 수부터 역순으로 처리되므로 괜춘
    groupNum--;
    while (groupNum >= 1) {
      newType = newType.replace('_GROUP_' + groupNum, randomize(groups['_GROUP_' + groupNum]));
      groupNum--;
    }
    var newArray = [];
    var srcArrayUsedIndex = {}; //동일한 요소가 반복 사용되는 것을 방지
    newType.split(',').forEach(function (k) {
      for (var i = 0; i < srcArray.length; i++) {
        if (srcArray[i][numColumn] == k) {
          if (srcArrayUsedIndex[k] == true) continue;
          srcArrayUsedIndex[k] = true;
          newArray.push(srcArray[i]);
          continue;
        }
      }
    });
    //srcArray에는 있는데 입력받은 SEED에 없어서 추가되지 못한 것들을 뒷부분에 추가해 준다
    srcArray.forEach(function (s) {
      var sNum = s[numColumn];
      var exists = false;
      for (var n = 0; n < newArray.length; n++) {
        if (newArray[n][numColumn] == sNum) {
          exists = true;
          break;
        }
      }
      //없으면 추가
      if (!exists) newArray.push(s);
    });
    return newArray;

    //입력받은 규칙을 콤마 형태로 변환
    function randomTypeToComma(array, type) {
      var i, totalLength, fixCount;
      //전체섞기
      if (['TRUE', 'ALL', 'RANDOM'].indexOf(type.toUpperCase()) >= 0) {
        type = '(' + array.join(',') + ')';
      } else if (type.match(/^-[0-9]+$/) != null) {
        //맨 뒤 n개만 고정하고 전체 섞기
        totalLength = array.length;
        fixCount = -Number(type.match(/^-[0-9]+$/)[0]);
        type = array.splice(totalLength - fixCount).join(',');
        type = '(' + array.join(',') + '),' + type;
      } else {
        //물결을 콤마로 분리 1~4 => 1,2,3,4
        type = type.replace(/([0-9]+)~([0-9]+)/g, function (match, start, end) {
          start = Number(start);
          end = Number(end);
          var addnum = start > end ? -1 : 1;
          var ary: any = [];
          while (start != end + addnum) {
            ary.push(start);
            start += addnum;
          }
          return ary.join(',');
        });
      }
      return type;
    }

    //그룹을 입력받아 섞음
    // (1,2,3) : 괄호 안을 섞음
    // {1,2,3} : 괄호 안을 로테이션함 (2,3,1) (3,1,2)
    // [1,2,3] : 괄호 안의 순서 고정
    function randomize(group) {
      var type = String(group).substr(0, 1);
      var array = group.substr(1, group.length - 2).split(',');
      var output: any = [],
        idx;
      //완전 섞기
      if (type == '(') {
        while (array.length > 0) {
          idx = Math.floor(array.length * Math.random());
          output.push(array[idx]);
          array.splice(idx, 1);
        }
      } else if (type == '{') {
        //로테이션
        output = array.splice(Math.floor(array.length * Math.random()));
        output = output.concat(array);
      } else if (type == '[') {
        //고정
        output = array;
      }
      return output.join(',');
    }
  }

  removeImageTagInContents(contents: string, regex: RegExp, replaceValue): string {
    return contents.replace(regex, replaceValue);
  }

  public imageTagGenerator(dataUrl: string): string {
    return `<p style="max-width: 500px; max-height: 320px; margin: auto;"><img src="${dataUrl}" style="object-fit: contain; max-width:100%; max-height: 320px; width: fit-content; height: auto;" alt="질문 부가 설명 이미지"></p>`;
  }

  public imageTagGeneratorInDesc(dataUrl: string): string {
    return `<p><img src="${dataUrl}" style="object-fit: contain; max-width: 100%; max-height: 100%; width: fit-content; height: auto;" alt="질문 부가 설명 이미지"></p>`;
  }

  public removeImageTag(contents: string): string {
    return contents.replace(/<P|(.*?)><IMG(.*?)><\/P|(.*?)>/gi, '');
  }

  //색상 2개 입력받아 그라디언트 만들기
  makeGradient(colorStr: string, num: number) {
    //makeGradient("#F5FAFE,#ABCCF3",5);
    //--->["#f5fafe", "#e3effb", "#d0e3f9", "#bed8f6", "#abccf3"]

    if ((colorStr || '') == '') {
      return null;
    }
    const colors = colorStr.split(',');
    const start = colors[0].replace(/#/g, '');
    let end;
    if (colors.length == 1) {
      end = start;
    } else {
      end = colors[1].replace(/#/g, '');
    }
    let startR, startG, startB;
    let endR, endG, endB;
    if (start.length == 3) {
      startR = parseInt(start.substr(0, 1) + start.substr(0, 1), 16);
      startG = parseInt(start.substr(1, 1) + start.substr(1, 1), 16);
      startB = parseInt(start.substr(2, 1) + start.substr(2, 1), 16);
    } else if (start.length == 6) {
      startR = parseInt(start.substr(0, 2), 16);
      startG = parseInt(start.substr(2, 2), 16);
      startB = parseInt(start.substr(4, 2), 16);
    }
    if (end.length == 3) {
      endR = parseInt(end.substr(0, 1) + end.substr(0, 1), 16);
      endG = parseInt(end.substr(1, 1) + end.substr(1, 1), 16);
      endB = parseInt(end.substr(2, 1) + end.substr(2, 1), 16);
    } else if (end.length == 6) {
      endR = parseInt(end.substr(0, 2), 16);
      endG = parseInt(end.substr(2, 2), 16);
      endB = parseInt(end.substr(4, 2), 16);
    }
    const output: string[] = [];
    for (let i = 0; i < num; i++) {
      const r = (Math.round((i * (endR - startR)) / (num - 1) + startR) + 256).toString(16).substr(1);
      const g = (Math.round((i * (endG - startG)) / (num - 1) + startG) + 256).toString(16).substr(1);
      const b = (Math.round((i * (endB - startB)) / (num - 1) + startB) + 256).toString(16).substr(1);
      output.push('#' + r + g + b);
    }
    return output;
  }

  makeToast(message: string, variant: string, bToast: any): void {
    bToast.toast(message, { variant: variant, noCloseButton: true, autoHideDelay: 3000, bodyClass: 'toast-class' });
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $common: CommonClass;
  }
}

export default {
  install(Vue: any) {
    Vue.prototype.$common = new CommonClass();
  },
};
