
import { Component, Vue } from 'vue-property-decorator';
import ProjectTopMenu from '@/components/project/ProjectTopMenu.vue';
import { QUESTION } from '@/types/question';
import QuotaParticipation from '@/components/project/quota/participation/QuotaParticipation.vue';
import QuotaLinkModal from '@/components/project/quota/QuotaLinkModal.vue';
import LinkIcon from '@/components/project/make/options/common/icons/LinkIcon.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import QRCode from 'qrcode';

@Component({
  components: {
    QuotaParticipation,
    ProjectTopMenu,
    QuotaLinkModal,
    LinkIcon,
  },
})
export default class Quota extends Vue {
  testLink: string = '';
  realLink: string = '';
  linkStatus: QUESTION.LINK_STATUS = QUESTION.LINK_STATUS.DEFAULT;
  status: QUESTION.SVY_STATUS_TYPE = QUESTION.SVY_STATUS_TYPE.HOLD;
  QUESTION: Object = QUESTION;
  insertDate: string = '';
  startDate: string = '';
  endDate: string = '';
  holdMsg: string = '';
  stopMsg: string = '';
  endMsg: string = '';
  limitCount: number = 0;
  surveyType: number = 0;
  surveyTitle = '';
  private projectId: number = -1;
  private loading: boolean = true;
  private surveyParticipations = {
    ALL: { NAME: 'ALL', COUNT: 0 },
    BAD: { NAME: 'BAD', COUNT: 0 },
    COMPLETE: { NAME: 'COMPLETE', COUNT: 0 },
    DROP: { NAME: 'DROP', COUNT: 0 },
    OVER: { NAME: 'OVER', COUNT: 0 },
    OUT: { NAME: 'OUT', COUNT: 0 },
  };

  get link(): string {
    return this.linkStatus === QUESTION.LINK_STATUS.DEFAULT
      ? ''
      : this.linkStatus === QUESTION.LINK_STATUS.TEST
      ? this.testLink
      : this.realLink;
  }

  created(): void {
    this.projectId = Number(this.$route.params.id);
  }

  statusClick(value) {
    if (!this.endDate) {
      this.$common.makeToast('설문 종료 날짜를 선택해주세요.', ToastVariant.DANGER, this.$bvToast);
      return;
    }
    this.status = value;
    this.statusSave();
  }

  async copyToClipboard() {
    await navigator.clipboard.writeText(this.link);
    this.$common.makeToast('링크가 복사되었습니다.', ToastVariant.SUCCESS, this.$bvToast);
  }

  async mounted(): Promise<void> {
    await this.load();
  }

  async load() {
    try {
      this.loading = true;
      const { data } = await this.axios.get(`/project/quota/info/${this.projectId}`);
      const { info, realUrl, testUrl, quota } = data;
      const { CONFIG, DATA, TITLE } = info;
      const {
        STATUS,
        STATUS_HOLD_MSG,
        STATUS_STOP_MSG,
        STATUS_END_MSG,
        OPEN_END_DT,
        OPEN_START_DT,
        SIMPLE_SURVEY_TYPE,
        LINK_STATUS = QUESTION.LINK_STATUS.DEFAULT,
      } = CONFIG;

      const linkActive = (LINK_STATUS >= QUESTION.LINK_STATUS.FW_START && LINK_STATUS <= QUESTION.LINK_STATUS.FW_SHUT_DOWN);

      if (SIMPLE_SURVEY_TYPE === 1 || !linkActive) {
        this.$common.makeToast('잘못된 접근입니다.', ToastVariant.DANGER, this.$bvToast);
        await this.$router.replace(`/project/make/${this.projectId}`);
        return;
      }

      this.status = STATUS as QUESTION.SVY_STATUS_TYPE;
      this.startDate = OPEN_START_DT;
      this.endDate = OPEN_END_DT;
      this.holdMsg = STATUS_HOLD_MSG;
      this.stopMsg = STATUS_STOP_MSG;
      this.endMsg = STATUS_END_MSG;
      this.linkStatus = LINK_STATUS;
      this.limitCount = 0;
      this.surveyType = SIMPLE_SURVEY_TYPE;
      this.testLink = testUrl;
      this.realLink = realUrl;
      this.surveyTitle = TITLE;
      //최대 몇명까지 응답할 수 있느지 구하자
      const quotaArray = DATA.filter((item) => item.TYPE === QUESTION.QUESTION_TYPES.QUOTA);
      quotaArray.forEach((data) => {
        const { QUOTA } = data;
        let sum = 0;
        for (const row in QUOTA) {
          sum += Number(QUOTA[row]['quota']);
        }
        this.limitCount += sum;
      });

      if (quota) {
        const { all, drop, complete, bad, out, over } = quota;
        if (all) this.surveyParticipations.ALL = { NAME: 'ALL', COUNT: all };
        else this.surveyParticipations.ALL = { NAME: 'ALL', COUNT: 0 };
        if (bad) this.surveyParticipations.BAD = { NAME: 'ALL', COUNT: bad };
        else this.surveyParticipations.BAD = { NAME: 'BAD', COUNT: 0 };
        if (complete) this.surveyParticipations.COMPLETE = { NAME: 'ALL', COUNT: complete };
        else this.surveyParticipations.COMPLETE = { NAME: 'COMPLETE', COUNT: 0 };
        if (drop) this.surveyParticipations.DROP = { NAME: 'ALL', COUNT: drop };
        else this.surveyParticipations.DROP = { NAME: 'DROP', COUNT: 0 };
        if (out) this.surveyParticipations.OUT = { NAME: 'ALL', COUNT: out };
        else this.surveyParticipations.OUT = { NAME: 'OUT', COUNT: 0 };
        if (over) this.surveyParticipations.OVER = { NAME: 'ALL', COUNT: over };
        else this.surveyParticipations.OVER = { NAME: 'OVER', COUNT: 0 };
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  copyLink(): void {
    const urlLink = document.getElementById('shareLink') as HTMLInputElement;
    if (this.link === '') {
      this.$common.makeToast(ToastMessage.AFTER_CREATE_LINK, ToastVariant.DANGER, this.$bvToast);
      return;
    }
    if (urlLink) {
      urlLink.focus();
      urlLink.select();
      document.execCommand('copy');
      this.$common.makeToast(ToastMessage.LINK_COPY_SUCCESS, ToastVariant.SUCCESS, this.$bvToast);
    } else {
      this.$common.makeToast(ToastMessage.LINK_COPY_FAILED, ToastVariant.DANGER, this.$bvToast);
    }
  }

  /**
   * 진행상태 수정
   */
  async statusSave() {
    try {
      this.loading = true;
      const sendData = {
        STATUS: this.status,
        STATUS_HOLD_MSG: this.holdMsg,
        STATUS_STOP_MSG: this.stopMsg,
        STATUS_END_MSG: this.endMsg,
        OPEN_START_DT: this.startDate,
        OPEN_END_DT: this.endDate,
      };
      const { data } = await this.axios.put(`/project/status/${this.projectId}`, sendData);
      const { result } = data;
      if (result) this.$common.makeToast(ToastMessage.UPDATE_STATUS, ToastVariant.SUCCESS, this.$bvToast);

      const userId = this.$store.getters["userId"];
      await this.$store.dispatch('socketStore/unisurveyAlertUpdate',{
        userId,
        SNUM: this.projectId,
        ALERT_CHECK: false,
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  makeQrCode(): void {
    try {
      const link = this.link
      if (link.length === 0) {
        this.$common.makeToast('설문 링크가 존재하지 않습니다.', ToastVariant.DANGER, this.$bvToast);
        return;
      }
      QRCode.toDataURL(link, (err, dataURL) => {
        if (err) {
          this.$common.makeToast('잠시 후 다시 시도해주세요.', ToastVariant.DANGER, this.$bvToast);
        } else {
          const anchor = document.createElement('a');
          anchor.href = dataURL;
          anchor.download = `${this.surveyTitle}-설문-qrcode`;
          anchor.target = '_self';
          anchor.click();
          this.$common.makeToast('QR코드가 다운로드 되었습니다.', ToastVariant.SUCCESS, this.$bvToast);
        }
      })
    } catch (e) {
      this.$common.makeToast('잠시 후 다시 시도해주세요.', ToastVariant.DANGER, this.$bvToast);
    }
  }
}
