export default {
  policyContent:
    '<b>제1장 총칙</b><br /><br />' +
    '<b>제1조 목적</b><br /><br />' +
    '이 약관은 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")"에 의하여 롯데멤버스(이하“회사”)가 제공하는 유니서베이 서비스(이하 "서비스"라 합니다)의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.<br /><br />' +
    '<b>제2조 약관의 효력과 변경</b><br /><br />' +
    '1. 본 약관은 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")" 규정에 의하여 제정한 것이며 공시함으로써 효력을 발생합니다. 본 약관의 공시는 서비스 홈페이지에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력을 발생합니다.<br /><br />' +
    '2. 회사는 원활한 서비스 제공 및 서비스 개선을 목적으로 본 약관을 변경할 수 있으며, 약관 개정시 사전에 회원에게 공지하여 변경된 사항에 대해서 인지할 수 있도록 조치합니다.<br /><br />' +
    '<b>제3조 용어의 정의</b><br /><br />' +
    '1. 본 약관에서 사용하는 용어는 다음과 같습니다.<br /><br />' +
    '① 회원 : 서비스를 이용하기 위하여 약관에 동의하고 회원 ID를 부여 받은 자를 의미합니다.<br /><br />' +
    '② 회원 ID : 서비스 가입회원의 식별을 위하여 부여하는 고유한 ID로 이메일 주소를 사용합니다.<br /><br />' +
    '③ 비밀번호 : 회원의 서비스 이용의 권익보호를 위하여 회원이 선정할 수 있는 숫자와 문자의 조합을 말합니다.<br /><br />' +
    '④ 계약해지 : 회사나 신청자가 서비스 개통 후 서비스 이용계약을 해약하는 것을 말합니다. 이는 회원탈퇴를 전제로 합니다.<br /><br />' +
    '⑤ 서비스 중단 : 회사가 일정기간 서비스 이용을 제한 또는 유보하는 것을 말합니다.<br /><br />' +
    '⑥ 서비스 홈페이지 : 회원이 서비스를 이용하는 온라인 홈페이지(unisurvey.co.kr)를 말합니다.<br /><br />' +
    '⑦ 사전통보 : 회원의 서비스 이용 중단 및 서비스 이용 제한 등의 사유가 발생시, 회사가 해당 사유에 상응하는 조치를 취하기 전에 회원에게 온라인을 통해 알리는 것을 말합니다.<br /><br />' +
    '<b>제2장 회원가입 계약</b><br /><br />' +
    '<b>제4조 회원가입</b><br /><br />' +
    '1. “회원”으로 가입하고자 하는 고객(이하 “가입신청자”라 함)은 “서비스홈페이지”(unisurvey.co.kr)를 통해 본 약관 및 별도의 개인정보의 수집, 제공 및 활용에 관한 동의서의 내용에 대하여 동의하고 당사가 요구하는 기본 회원정보를 입력하여 회원가입 신청을 합니다.<br /><br />' +
    '<b>제5조 회원정보의 변경 및 탈퇴</b><br /><br />' +
    '1. “회원”은 회원 정보 관리 화면을 통하여 본인의 정보를 열람하고 수정할 수 있습니다.<br /><br />' +
    '2. “회원”은 당사에 등록한 회원정보에 변경이 있는 경우, 상당한 기간 이내에 당사에 대하여 “서비스홈페이지”의 회원정보 수정 등의 방법으로 그 변경 사항을 알려야 합니다.<br /><br />' +
    '3. 제2항의 변경 사항을 당사에 알리지 않아 발생한 불이익에 대하여 당사는 책임지지 않습니다.<br /><br />' +
    '4. “회원”은 언제든지 “서비스홈페이지”를 통해 당사가 정하는 방법으로 회원탈퇴를 요청할 수 있으며, 당사는 “회원”의 요청에 따라 조속히 회원탈퇴에 필요한 제반 절차를 수행합니다.<br /><br />' +
    '5. 제4항에 따라 탈퇴한 “회원”에 대해서 당사는 서비스 운영 원칙에 따라 재가입을 제한할 수 있습니다.<br /><br />' +
    '<b>제 6 조 (개인정보보호 의무)</b><br /><br />' +
    '1. "회사"는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 "회사"의 개인정보처리방침이 적용됩니다. 다만, "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보처리방침이 적용되지 않습니다.<br /><br />' +
    '<b>제7조 “회원”의 “아이디” 및 “비밀번호”의 관리에 대한 의무</b><br /><br />' +
    '1. “회원”의 ID 및 비밀번호에 관한 관리책임은 “회원” 본인에게 있습니다. 회원 ID와 비밀번호의 관리를 소홀하여 발생하는 모든 책임은 “회원” 본인에게 있습니다.<br /><br />' +
    '2. “회원”은 자신의 회원ID가 부정하게 사용된 경우에 즉시 자신의 비밀번호를 바꾸고 그 사실을 당사에 통보하여야 합니다.<br /><br />' +
    '3. “회원”이 자신의 ID 및 비밀번호를 도용 당한 경우에는 즉시 당사에 통보하고 당사의 안내가 있는 경우에는 그에 따라야 합니다.<br /><br />' +
    '<b>제8조 “회사”의 의무</b><br /><br />' +
    '1. “회사”는 안정적인 서비스 제공을 위하여 지속적인 유지/보수의 노력을 해야 합니다. 단, 시스템 기반 시설에 장애가 발생하는 경우, 즉시 수리 또는 복구를 진행해야 합니다.<br /><br />' +
    '2. “회사”는 “회원”이 안전하게 “서비스”를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안 시스템을 갖추어야 하며 개인정보 처리방침을 공시하고 준수합니다.<br /><br />' +
    '3. “회사”는 “서비스” 이용과 관련하여 “회원”이 제기한 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. “회원”이 제기한 의견이나 불만 사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 “회원”에게 처리 과정 및 결과를 전달합니다.<br /><br />' +
    '4. “회사”는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신사업법 등 “서비스”의 운영, 유지와 관련한 법규를 준수합니다.<br /><br />' +
    '<b>제9조 “회원”의 의무</b><br /><br />' +
    '1. 회사는 회원의 서비스 이용 내용이 다음 각 호의 하나 이상에 해당되는 경우, 사전통보 없이 계약을 해지하거나 서비스 이용을 중단시킬 수 있습니다.<br /><br />' +
    '① 범죄적 행위 및 미풍양속, 공공질서에 반하는 행위에 해당하는 경우<br /><br />' +
    '② 다른 사람의 "서비스" 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우<br /><br />' +
    '③ 타인의 아이디 및 비밀번호를 도용한 경우<br /><br />' +
    '④ 타인의 명예를 손상시키거나 타인에게 불이익을 주는 행위를 하는 경우<br /><br />' +
    '⑤ 타인의 명의 또는 가명을 사용하여 신청하였을 경우<br /><br />' +
    '⑥ 유해한 내용을 담은 내용을 업로드 하는 경우<br /><br />' +
    '2. 회원이 서비스를 이용하는 도중 12개월간 연속하여 서비스 이용을 위한 로그인(log-in) 기록이 없는 장기미접속회원의 경우, 이메일을 통한 사전통보 후에 서비스이용을 중단시킬 수 있습니다. 이 경우 회원 개인 정보 및 결제이력, 서비스 이용에 따라 생성된 프로젝트 정보, 자료 등은 내부 규정에 따라 별도 보관 및 파기 됩니다. <br /><br />' +
    '4. 기타 정보통신망이용촉진 및 정보보호들에 관한 법률, 전자상거래 등에서의 소비자 보호에 관한 법률, 청소년 보호법 등의 관계 법령이나 회사가 정한 회원 약관에 근거하고 있는 이용조건에 위배되는 경우, 이메일을 통한 사전통보 후에 서비스 이용을 제한할 수 있습니다.<br /><br />' +
    '<b>제3장 서비스 이용 및 변경</b><br /><br />' +
    '<b>제10조 "서비스"의 내용</b><br /><br />' +
    '1. "회사"는 "회원"에게 아래와 같은 서비스를 제공합니다.<br /><br />' +
    '① 설문조사에 대한 용역 계약 체결<br /><br />' +
    '② 설문지 작성 도구 제공<br /><br />' +
    '③ 설문조사 결과 분석 자료의 제공<br /><br />' +
    '④ 기타 "회사"가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해 "회원"에게 제공하는 일체의 서비스<br /><br />' +
    '⑤ 이외 제공하는 모든 부가 서비스<br /><br />' +
    '<b>제11조 서비스 이용</b><br /><br />' +
    '1. 회사는 회원의 서비스 이용에 필요한 다음 각 호의 사항을 서비스 홈페이지 및 이메일을 통해 사전에 공지하여야 한다.<br /><br />' +
    '다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br /><br />' +
    '① 정기/비정기적인 시스템 점검 실시 계획<br /><br />' +
    '② 서비스 이용, 장애처리, 비용 등과 관련된 고객 지원을 위한 문의처<br /><br />' +
    '③ 서비스 Up-grade 등을 통해 주요 서비스 기능이 추가 되거나 변경되는 경우<br /><br />' +
    '④ 기타 서비스 이용 제한 사항 발생하는 경우<br /><br />' +
    '2. 회원은 회사에서 제공받은 서비스를 제3자에게 재판매 할 수 없습니다. 제3자란 서비스 이용승낙의 대상이 되는 해당 업체 및 개인을 제외한 타 업체 및 개인을 지칭합니다.<br /><br />' +
    '<b>제12조 서비스의 변경 및 중단</b><br /><br />' +
    '1. “회사”는 컴퓨터 등 전기통신설비의 보수점검·교체 및 고장, 통신의 두절 또는 운영상 상당한 이유가 있는 경우 “서비스”의 제공을 일시적으로 중단하거나 변경할 수 있습니다. 이 경우 당사는 제8조에 정한 방법으로 “회원”에게 통지합니다. 다만, 당사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br /><br />' +
    '2. “회사”는 “서비스”의 제공에 필요한 경우 정기 점검을 실시할 수 있으며, 정기 점검 시간은 “서비스” 제공 화면에 공지한 바에 따릅니다.<br /><br />' +
    '3. “회사”는 무료로 제공되는 “서비스”의 일부 또는 전부를 당사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 “회원”에게 별도의 보상을 하지 않습니다.<br /><br />' +
    '<b>제13조 서비스제공이 불가능한 경우의 처리방안 제시</b><br /><br />' +
    '1. 서비스제공 불가능 경우 : 천재지변, 오류(장애), 점검, 서비스양도, 서비스종료(폐업), 등<br /><br />' +
    '2. 서비스 종료 시 30일 전 회원에게 공지 합니다.<br /><br />' +
    '<b>제14조 정보의 제공 및 광고의 게재</b><br /><br />' +
    '1. “회사”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 당사의 서비스 등 다양한 정보를 공지사항, 이메일, 문자메세지 등의 방법으로 “회원”에게 제공할 수 있습니다. 다만, “회원”은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 위 정보 중 영리목적의 광고성 정보의 경우 언제든지 전자우편 등에 대해서 수신 거절을 할 수 있습니다.<br /><br />' +
    '<b>제15조 서비스 이용시간</b><br /><br />' +
    '1. 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 정한 날이나 시간은 제외합니다.<br /><br />' +
    '2. 회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.<br /><br />' +
    '<b>제16조 서비스 이용 요금</b><br /><br />' +
    '1. 회사는 서비스의 요금체계를 항상 서비스 홈페이지 및 서비스 내에 공지합니다.<br /><br />' +
    '2. 회사는 서비스요금을 변경할 수 있으며, 서비스요금이 변경되기 1개월 전에 서비스 홈페이지 및 서비스 내부에 공지합니다.<br /><br />' +
    '3. 서비스 요금이 변경되기 15일 전에 회원에게 사전통보를 합니다.<br /><br />' +
    '<b>제17조 이용자 불만형태별 처리절차 및 처리기간</b><br /><br />' +
    '1. 회사는 회원이 서비스를 이용함에 있어 불만사항 및 이용문의 사항을 다음과 같이 요청할 수 있으며, 회사는 회원의 불만사항을 최소화하고자 노력해야 합니다.<br /><br />' +
    '① 회원이 서비스를 이용함에 있어 결제 내역 및 요금과 관련한 불만사항을 제기한 경우 회사는 해당 사항의 사실 유무를 확인 후 즉시 처리하도록 합니다.<br /><br />' +
    '② 회원이 서비스를 이용함에 있어 서비스 품질이나 시스템 관련 불만사항을 제기한 경우 회사는 해당 사항의 사실 유무를 확인 후 즉시 처리하도록 합니다.<br /><br />' +
    '③ 회원이 서비스를 이용함에 있어 명의도용 및 아이디, 비밀번호 등의 개인정보와 장기미접속회원 전환 및 복구 등과 관련한 불만사항을 제기한 경우 회사는 해당 사항의 사실 유무를 확인 후 즉시 처리 하도록 합니다.<br /><br />' +
    '④ 회원이 서비스를 이용함에 있어 진행중인 이벤트 및 프로모션에 대한 불만사항을 제기한 경우 회사는 해당 사항의 사실 유무를 확인 후 즉시 처리 하도록 합니다.<br /><br />' +
    '2. 회원이 서비스에 불만이 있을 시, 회사의 고객센터, 1:1온라인상담 등을 이용하여 24시간 건의 할 수 있습니다.<br /><br />' +
    '3. 회사는 고객센터, 1:1온라인상담 등의 이용자 불만사항에 대하여 영업일 기준 최장 24시간 이내 답변 및 조치사항을 회신하여야 하며 부득이하게 조치가 길어지는 경우 처리 일정 및 내용에 대해 지속적으로 회신합니다.<br /><br />' +
    '<b>제4장 지적재산권</b><br /><br />' +
    '<b>제 19 조 (지적재산권)</b><br /><br />' +
    '1. 회사가 지적재산권을 가지고 있는 컨텐츠, 서비스, 소프트웨어, 상표 등을 회사의 서면동의 없이 전부 또는 일부의 수정, 대여, 대출, 배포, 제작, 양도, 재라이센스, 담보권 설정 행위, 상업적 이용 행위를 할 수 없으며 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.<br /><br />' +
    '양자 간에 분쟁이 발생할 경우 상 관례 및 상호 신뢰에 따라 해결함을 원칙으로 하되, 해결이 불가능할 경우 회사의 본사 소재지를 관할하는 법원을 관할법원으로 합니다.<br /><br />',
  privateContent:
    '롯데멤버스(이하”회사”)는 이용자의 개인정보를 ‘동의를 기반으로 개인정보를 수집’ 하고 있으며, ‘이용자의 권리 (개인정보 자기결정권)를 적극적으로 보장’ 합니다. 회사는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 준수하고 있습니다. 개인정보처리방침은 관련 법률 및 지침의 변경과 회사 내부 운영방침의 변경에 따라 변경 될 수 있으니 홈페이지(unisurvey.co.kr) 방문 시 수시로 확인하여 주시기 바랍니다.<br /><br />' +
    '개인정보처리방침은 다음과 같은 내용을 담고 있습니다.<br /><br />' +
    '<b>제1장 개인정보 수집 항목</b><br /><br />' +
    '① 회사는 필요 최소한의 개인정보를 다음과 같이 수집하고 있습니다.<br /><br />' +
    '회원가입<br /><br />' +
    '[필수] 아이디 계정(이메일), 휴대전화번호<br /><br />' +
    '② 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.<br /><br />' +
    '신용카드 결제 시: 카드사명, 카드번호(일부) 등<br /><br />' +
    '계좌이체 시: 계좌번호, 은행명<br /><br />' +
    '③ 서비스 이용과정에서 이용자로부터 수집하는 개인정보는 아래와 같습니다.<br /><br />' +
    'IP주소, 방문일시, 인증 이력, 서비스 이용 기록, PC웹, 결제 기록<br /><br />' +
    '<b>제2장 개인정보 수집 및 이용 목적</b><br /><br />' +
    '개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는 반드시 사전에 이용자에게 동의를 구하도록 하겠습니다.<br /><br />' +
    '① 서비스 가입, 이용자 식별, 본인 확인, 고객 상담, 서비스 이용 철회 처리 등의 회원 관리<br /><br />' +
    '② 서비스 상품 구매에 따른 본인인증, 구매 및 요금 정산<br /><br />' +
    '③ 서비스 방문 및 이용 기록의 분석과 서비스 이용에 대한 통계 등을 기반으로 맞춤형 서비스 제공 및 기존 서비스 개선, 신규 서비스 요소 개발 등 서비스 이용 환경 구축<br /><br />' +
    '④ 부정 이용(거래) 등의 법령 및 이용약관을 위배하거나 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 이용자에 대한 제한 조치 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영<br /><br />' +
    '⑤ 광고성 정보 제공 등 마케팅 및 프로모션 소식 전달, 이벤트 정보 및 참여기회 제공<br /><br />' +
    '⑥ 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축<br /><br />' +
    '<b>제3장 개인정보의 처리 위탁</b><br /><br />' +
    '회사는 서비스 이행을 위해 아래와 같이 개인정보 처리 업무를 외부업체에 위탁하여 운영하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. <br /><br />' +
    `
    <table class="table border">
    <tr>
        <td class="border text-center"><b>수탁 업체</b></td>
        <td class="border text-center"><b>위탁업무 내용</b</td>
        <td class="border text-center"><b>개인정보 보유 및 이용기간</b</td>
    </tr>
    <tr>
        <td class="border text-center">NICE페이먼츠㈜</td>
        <td class="border text-center">신용카드 결제, 계좌이체 결제</td>
        <td class="border text-center" rowspan="2">회원탈퇴 시 혹은 <br/> 위탁 계약 종료 시까지</td>
    </tr>
    <tr>
        <td class="border text-center">㈜다날</td>
        <td class="border text-center">본인인증</td>
    </tr>
    </table>  
` +
    '<b>제4장 개인정보 보유 및 이용기간</b><br /><br />' +
    '회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br /><br />' +
    '단, 다음과 같은 사유에 해당하는 경우, 기재된 기한까지 개인정보를 보관 및 이용합니다.<br /><br />' +
    '관계법령에 따른 회원정보 보관<br /><br />' +
    '회사는 관계 법령의 규정에 의하여 보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정한 기간 동안 개인정보를 보관하며, 다른 목적으로는 절대 이용하지 않습니다.<br /><br />' +
    '① 통신비밀보호법<br /><br />' +
    '- 접속 로그, IP정보, 서비스 이용기록 : 3개월<br /><br />' +
    '② 전자상거래 등에서의 소비자보호에 관한 법률<br /><br />' +
    '- 계약 또는 청약철회 등에 관한 기록 : 5년<br /><br />' +
    '- 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br /><br />' +
    '- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br /><br />' +
    '<b>제5장 개인정보 파기절차 및 방법</b><br /><br />' +
    '이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성되면 지체 없이 파기하며, 절차 및 방법은 아래와 같습니다.<br /><br />' +
    '① 출력된 정보는 분쇄기로 분쇄하거나, 소각을 통해 파기합니다.<br /><br />' +
    '② 전자적 파일 형태인 경우 복구 및 재생되지 않도록 안전하게 삭제하고, 어떠한 용도로도 열람 또는 이용될 수 없도록 처리합니다.<br /><br />' +
    '③ 회사는 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.<br /><br />' +
    '<b>제6장 개인정보 동의를 거부할 권리 및 거부 경우의 불이익</b><br /><br />' +
    '귀하께서는 위와 같이 수집하는 개인정보에 대해, 동의하지 않거나 개인정보를 기재하지 않음으로써 거부할 수 있습니다. 다만, 이때 회원에게 제공되는 서비스가 제한될 수 있습니다.<br /><br />',
};
