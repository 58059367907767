
import { Component, Vue } from 'vue-property-decorator';
import ProjectTopMenu from '@/components/project/ProjectTopMenu.vue';
import questionUnit from '@/components/project/payment/question/unit.vue';
import estimate from '@/components/project/payment/estimate/index.vue';
import LinkIcon from '@/components/project/make/options/common/icons/LinkIcon.vue';

@Component({
  components: {
    ProjectTopMenu,
    questionUnit,
    estimate,
    LinkIcon,
  },
})
export default class Payment extends Vue {
  projectId = -1;

  created(): void {
    this.projectId = Number(this.$route.params.id);
  }

}
