
import { Component, Vue } from 'vue-property-decorator';
import ProjectTopMenu from '@/components/project/ProjectTopMenu.vue';
import questionUnit from '@/components/project/payment/question/unit.vue';
import estimate from '@/components/project/payment/estimate/index.vue';
import { QUESTION } from '@/types/question';
import LinkIcon from '@/components/project/make/options/common/icons/LinkIcon.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    ProjectTopMenu,
    questionUnit,
    estimate,
    LinkIcon,
  },
})
export default class SurveyPriceInfo extends Vue {
  title: string = '';
  testLink: string = '';
  linkStatus: QUESTION.LINK_STATUS = QUESTION.LINK_STATUS.DEFAULT;
  status: QUESTION.SVY_STATUS_TYPE = QUESTION.SVY_STATUS_TYPE.HOLD;
  QUESTION: Object = QUESTION;
  comment = '';
  questions: {
    text: string;
    value: number;
    valueText: string;
    type: string;
    show: boolean;
    sub: boolean;
    cursor: boolean;
  }[] = [];
  settingQuota = null;
  questionsTotal: number = 0;
  price: number = 0;
  resultPrice: number = 0;
  priceError: boolean = false;
  priceErrorMessage: string = '';
  projectId: number = -1;
  loading: boolean = true;
  isPanel = true;

  get link(): string {
    return this.testLink;
  }

  created(): void {
    this.projectId = Number(this.$route.params.id);
  }

  async mounted(): Promise<void> {
    await this.$question.initQuestion(this.projectId);
    await this.load();
  }

  async load() {
    try {
      this.loading = true;

      const { data } = await this.axios.get(`/project/payment/info/${this.projectId}`);
      const { info, testUrl, questions, questionTotal, projectPrice } = data;
      const { CONFIG, TITLE, PLACEHOLDER } = info;
      const { STATUS, LINK_STATUS = QUESTION.LINK_STATUS.DEFAULT, SIMPLE_SURVEY_QUOTA, SIMPLE_SURVEY_TYPE } = CONFIG;

      if (LINK_STATUS === 3 && !!CONFIG?.SIMPLE_SURVEY_CHECKLIST?.COMMENT) this.comment = CONFIG.SIMPLE_SURVEY_CHECKLIST.COMMENT;


      this.title = TITLE || PLACEHOLDER;
      this.status = STATUS as QUESTION.SVY_STATUS_TYPE;
      this.linkStatus = LINK_STATUS;
      this.settingQuota = SIMPLE_SURVEY_QUOTA;
      this.testLink = testUrl;
      this.questions = questions;
      this.questionsTotal = questionTotal;
      this.isPanel = SIMPLE_SURVEY_TYPE === 1;
      const { error, errorMessage, price, resultPrice } = projectPrice;
      this.price = price;
      this.resultPrice = resultPrice;
      this.priceError = error;
      this.priceErrorMessage = errorMessage;
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  testGo() {
    window.open(this.testLink);
  }
}
