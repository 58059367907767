
import { Component, Vue } from 'vue-property-decorator';
import LimeLogo from '@/components/icons/LimeLogo.vue';

@Component({
  components: { LimeLogo },
})
export default class VerifyWait extends Vue {
  loading: boolean = true;
  userId: string = '';
  userName: string = '';
  emailAuth: boolean = false;

  created() {
    this.userId = this.$route.params.userId;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/user/verify-wait/${this.userId}`);
      if (data) {
        const { name, emailAuth } = data;
        this.emailAuth = emailAuth;
        this.userName = name;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  redirectLogin() {
    this.$router.replace({ path: '/' });
  }

  async reSend() {
    try {
      const confirm = await this.$bvModal.msgBoxConfirm(`[${this.userId}] 인증메일을 재발송 하시겠습니까?`, {
        title: '',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      });
      if (!confirm) return;
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}
