
import { Component, Vue } from 'vue-property-decorator';
import QuestionWriteModal from '@/components/board/QuestionWriteModal.vue';
import { Bootpay } from '@bootpay/client-js';
import { IDanalAuth } from '@/interface/user/user';
import { AUTH_KEY } from '@/types/constant';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import PolicyModal from "@/components/modal/PolicyModal.vue";

@Component({
  components: {
    PolicyModal,
    QuestionWriteModal,
  },
})
export default class SignUpForm extends Vue {
  isLoading = false;
  userId = '';
  userName = '';
  userNickname = '';
  userPwd = '';
  userPwdConf = '';
  userPhone = '';
  userObjectId = '';
  phoneConfirm = false;
  userType = 'NORMAL';
  userEnterprise = '';

  agreement: boolean = false;
  isAvailableId: boolean | 'stay' = 'stay';
  withdrawal: boolean = false;
  join: boolean = true;

  idReg(): boolean {
    const idRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return idRegExp.test(this.userId);
  }

  showPolicy(type: string) {
    this.$nextTick(() => {
      this.$bvModal.show(type);
    });
  }

  changeIdValue() {
    const isCheckingId = this.isAvailableId;
    const isStay = this.isAvailableId === 'stay';
    if (isCheckingId && !isStay) this.isAvailableId = 'stay';
  }

  async duplicateIdCheck() {
    const isIdReg = this.idReg();
    const isCheckingId = this.isAvailableId;
    const isStay = this.isAvailableId === 'stay';
    if (!isIdReg) return;
    if (isCheckingId && !isStay) return;

    try {
      const { data } = await this.axios.post(`/user/duplicate-id/${this.userId}`);
      const { exists, use } = data;
      if (!exists) {
        this.$common.makeToast(ToastMessage.ID_CHECK, ToastVariant.SUCCESS, this.$bvToast);
      }
      this.isAvailableId = !exists;
      this.withdrawal = use;
    } catch (e) {
      console.log(e);
    }
  }

  questionModalOpen() {
    this.$bvModal.show('question-write-modal');
  }

  userEnterpriseReset() {
    this.userEnterprise = '';
  }

  get addEnterprise() {
    return this.userType === 'COMPANY' || this.userType === 'UNIVERSITY';
  }

  get validationId(): boolean | null {
    const isIdReg = this.idReg();
    const isNull = this.userId.length === 0;

    if (isNull) return null;
    if (!this.isAvailableId || this.userId.length > 30) return false;
    return isIdReg;
  }
  get validationPassword(): boolean | null {
    const pwdRegExp = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[$@!%*#?&])[a-zA-Z\d$@!%*#?&]{8,}$/;

    const isOkPwdReg = pwdRegExp.test(this.userPwd);
    const isNull = this.userPwd.length === 0;

    if (isNull) return null;

    return isOkPwdReg;
  }

  get validationConfPassword(): boolean | null {
    const isNull = this.userPwdConf.length === 0;

    if (isNull) return null;

    return this.userPwd === this.userPwdConf;
  }

  get validationPhone(): boolean | null {
    const phoneRegExp = /^01([016789])-?([0-9]{3,4})-?([0-9]{4})$/;
    const isOkPhoneReg = phoneRegExp.test(this.userPhone);
    const isNull = this.userPhone.length === 0;
    if (isNull) return null;
    return isOkPhoneReg;
  }

  get validationIdMessage(): string | void {
    const isRegCheck = this.idReg();
    const isStay = this.isAvailableId === 'stay';
    if (!isRegCheck || isStay) {
      return '형식에 맞지 않습니다';
    } else if (!isStay && !this.withdrawal) {
      return '사용 불가능한 이메일 입니다.';
    } else {
      return '이미 존재하는 아이디입니다.';
    }
  }

  get validationPwdMessage(): string | void {
    const isEqualPassword = true;
    if (!isEqualPassword) {
      return '비밀번호가 같지 않습니다.';
    } else {
      return '비밀번호의 형식을 맞춰주십시오';
    }
  }

  get validationPwdConfMessage(): string | void {
    return '비밀번호가 같지 않습니다.';
  }

  get validationPhoneMessage(): string | void {
    return '폰번호를 정확히 입력해주세요';
  }

  get validationJoin() {
    return (
      this.validationId &&
      this.validationPhone &&
      this.validationPassword &&
      this.validationConfPassword &&
      this.agreement
    );
  }

  async createUser(): Promise<void | boolean> {
    try {
      if (!this.validPassword()) {
        return false;
      }

      if (!this.agreement) {
        this.$common.makeToast(ToastMessage.NOT_POLICY_AGREE, ToastVariant.DANGER, this.$bvToast);
        return false;
      }

      if (!this.phoneConfirm) {
        this.$common.makeToast(ToastMessage.NOT_AUTH, ToastVariant.DANGER, this.$bvToast);
        return false;
      }

      if (!this.validEnterprise()){
        return false;
      }

      this.isLoading = true;

      const sendData = {
        userId: this.$common.rsaEncrypt(this.userId),
        userPwd: this.$common.rsaEncrypt(this.userPwd),
        userName: this.$common.rsaEncrypt(this.userName),
        userNickname: this.$common.rsaEncrypt(this.userNickname),
        userPhone: this.$common.rsaEncrypt(this.userPhone),
        userObjectId: this.userObjectId,
        userType: this.userType,
        userEnterprise: this.$common.rsaEncrypt(this.userEnterprise)
      };
      const { data } = await this.axios.post('/user/join', sendData);
      const { result, message } = data;

      if (result) {
        await this.$store.dispatch('socketStore/joinSuccess');
        this.$common.makeToast(message, ToastVariant.SUCCESS, this.$bvToast);

        setTimeout(() => {
          this.isLoading = false;
          location.href = `${process.env.VUE_APP_PRODUCTION_HOST}/users/join/success`;
        }, 500);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  validEnterprise(){
    if(this.userType !== 'NORMAL' && this.userEnterprise.length > 100){
      return false;
    }
    return true;
  }

  validPassword() {
    if (this.userPwd !== this.userPwdConf) {
      this.$common.makeToast(ToastMessage.PASSWORD_CHECK1, ToastVariant.DANGER, this.$bvToast);
      return false;
    }

    if (this.userPwd.length < 8) {
      this.$common.makeToast(ToastMessage.PASSWORD_CHECK2, ToastVariant.DANGER, this.$bvToast);
      return false;
    }

    if (this.userPwd.length > 20) {
      this.$common.makeToast(ToastMessage.PASSWORD_CHECK3, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    return true;
  }

  goLogin() {
    this.$router.push('/login');
  }

  async existsPhone() {
    try {
      if (!this.validationId) {
        this.$common.makeToast(
          ToastMessage.ID_CHECK2,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      } else if (!this.validationPhone) {
        this.$common.makeToast(
          ToastMessage.PHONE_CHECK,
          ToastVariant.DANGER,
          this.$bvToast
        );
        return;
      }
      this.isLoading = true;
      const { data } = await this.axios.get('/user/phone-check',{
        params: {
          phone: this.userPhone,
        },
      });
      const { result, exist, objectId } = data;
      if (result && !exist) {
        this.userObjectId = objectId;
        await this.authBootpay();
      } else if (exist) {
        this.$common.makeToast(ToastMessage.EXISTS_PHONE, ToastVariant.DANGER, this.$bvToast);
      }
      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }
  }

  async authBootpay() {
    try {
      this.isLoading = true;
      const phone = this.userPhone;
      const response = await Bootpay.requestAuthentication({
        application_id: AUTH_KEY.APPLICATION_ID,
        pg: AUTH_KEY.PG,
        order_name: '본인인증',
        authentication_id: 'auth_' + new Date().getTime(),
        user: {
          phone,
        },
        extra: {},
      });
      const { result, auth } = await this.authData(response);

      if (result) await this.setAuthDone(auth);
      else await this.setAuthHistorySave(auth);
      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }
  }

  async authData(response): Promise<{ result: boolean; auth: IDanalAuth }> {
    switch (response.event) {
      case 'done':
        const { receipt_id } = response.data;
        const { data } = await this.axios.get(`/boot-pay/auth/${receipt_id}`);
        return { result: true, auth: data };
      case 'cancel':
        return { result: false, auth: response.data };
      case 'error':
        return { result: false, auth: response.data };
      default:
        return { result: false, auth: response.data };
    }
  }

  async setAuthHistorySave(item): Promise<void> {
    try {
      const sendData = {
        ...item,
        _id: this.userObjectId,
        phone: this.userPhone,
      };
      await this.axios.post(`/boot-pay/auth-history`, sendData);
    } catch (e) {
      console.error(e);
    }
  }

  async setAuthDone(item) {
    try {
      this.isLoading = true;
      const { receipt_id, authenticate_data, method } = item;
      const { birth, gender, name } = authenticate_data;
      const sendData = {
        receipt_id,
        birth,
        gender,
        name,
        action: method,
        _id: this.userObjectId,
        phone: this.userPhone,
      };

      const { data } = await this.axios.post( '/boot-pay/auth', sendData);
      const { result } = data;
      if (result) {
        this.userName = name;
        this.userNickname = name;
        this.phoneConfirm = true;
        this.$common.makeToast(ToastMessage.AUTH_SUCCESS, ToastVariant.SUCCESS, this.$bvToast);
      }
      this.isLoading = false;
    } catch (e) {
      console.error(e);
    }
  }
}
