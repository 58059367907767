
import { Component, Vue } from 'vue-property-decorator';
import LimeLogo from '@/components/icons/LimeLogo.vue';

@Component({
  components: { LimeLogo },
})
export default class SignUpSuccessModal extends Vue {
  close() {
    this.$bvModal.hide('join-success');
  }
}
