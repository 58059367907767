
import {Component, Vue} from "vue-property-decorator";
import {ToastMessage, ToastVariant} from "@/utils/ToastEnum";

@Component({})
export default class AdminCheck extends Vue{
  token = '';
  path = '';

  async created(){
    this.urlMaker();
    await this.tokenDecode();
  }

  async tokenDecode() {
    try {
      const { data } = await this.axios.get(`/user/join-verify-success/${this.token}`);
      const { result, userId } = data;
      if (result) {
        const loginData = {
          _id: userId,
          token: this.token
        }
        await this.login(loginData);
      }
    } catch (e) {
      this.$common.makeToast(ToastMessage.INVALID, ToastVariant.DANGER, this.$bvToast);
    }
  }

  async login(loginData: {_id: string, token: string}) {
    try {
      await this.$store.dispatch('login', { ...loginData });
      await this.$router.push(`/${this.path}`);
    } catch (e) {
      this.$common.makeToast(ToastMessage.INVALID, ToastVariant.DANGER, this.$bvToast);
    }
  }

  /*
    @description: /:domain/:routerType/:id/:token
   */
  private urlMaker() {
    const fullPath = this.$route.path.split('/').filter(Boolean);
    const tokenIndex = fullPath.length - 1;
    this.token = fullPath[tokenIndex];
    this.path = fullPath.slice(0, tokenIndex).join('/');
  }
}
